import { toast, ToastOptions, TypeOptions } from 'react-toastify'

import Icon from '../Icon'

type AlertProps = {
  text: string
  closeToast?: () => void
  type: TypeOptions
}

const getBackgroundColor = (type: TypeOptions) => {
  switch (type) {
    case 'default':
      return 'black'

    case 'error':
      return 'red'

    case 'success':
      return 'green'

    case 'warning':
      return 'orange'

    case 'info':
      return 'blue'
  }
}

export default function Alert({ text, closeToast, type }: AlertProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 20px',
        backgroundColor: 'white',
        border: `3px solid ${getBackgroundColor(type)}`,
        textTransform: 'capitalize',
      }}
      className='overflow-hidden rounded-md font-f37'
    >
      <p className='xs'>
        {type !== 'default' && (
          <span
            style={{
              color: getBackgroundColor(type),
              fontWeight: 'bold',
            }}
          >
            {type}:
          </span>
        )}
        {' ' + text}
      </p>

      <button onClick={closeToast} style={{ padding: 5 }}>
        <Icon name='MdClose' size={20} />
      </button>
    </div>
  )
}

/**
 * React toastify wrapper function
 * To specify alert `type` use addAlert methods `error | success | info | warning` or pass type into `options` object
 * @param text text to render in alert
 * @param options alert options
 */
export function addAlert<Data extends object>(text: string, options?: ToastOptions<Data>) {
  toast<Data>(
    ({ toastProps, closeToast }) => (
      <Alert text={text} type={toastProps.type} closeToast={closeToast} />
    ),
    options,
  )
}

/**
 * Invokes error alert
 */
addAlert.error = <Data extends object>(text: string, options?: ToastOptions<Data>) => {
  toast<Data>(
    ({ closeToast }) => <Alert text={text} type='error' closeToast={closeToast} />,
    options,
  )
}

/**
 * Invokes success alert
 */
addAlert.success = <Data extends object>(text: string, options?: ToastOptions<Data>) => {
  toast<Data>(
    ({ closeToast }) => <Alert text={text} type='success' closeToast={closeToast} />,
    options,
  )
}

/**
 * Invokes info alert
 */
addAlert.info = <Data extends object>(text: string, options?: ToastOptions<Data>) => {
  toast<Data>(
    ({ closeToast }) => <Alert text={text} type='info' closeToast={closeToast} />,
    options,
  )
}

/**
 * Invokes warning alert
 */
addAlert.warning = <Data extends object>(text: string, options?: ToastOptions<Data>) => {
  toast<Data>(
    ({ closeToast }) => <Alert text={text} type='warning' closeToast={closeToast} />,
    options,
  )
}
