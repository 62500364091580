import { Address, Customer, CustomerAccessToken } from 'shopify-types'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { getLocaleFromHostname } from '@/helpers/getLocaleFromHostname'
import { logger } from '@/helpers/logger'
import { shopify as non_localized_shopify } from '@/shopify'
import { CreateAddressInput } from '@/shopify/customer/address/create'
import { CreateCustomerInput } from '@/shopify/customer/create'

import { useCart } from './cartStore'

const shopify = () =>
  non_localized_shopify({ language: getLocaleFromHostname(window.location.hostname).toUpperCase() })

export type CustomerStoreType = {
  customer: Customer | null
  token: CustomerAccessToken | null
  isLoaded: boolean
  setIsLoaded: (isLoaded: boolean) => void
  setCustomer: (customer?: Customer | null) => void
  setToken: (token?: CustomerAccessToken | null) => void
  register: (input: CreateCustomerInput) => Promise<void>
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  refresh: () => Promise<void>
  addAddress: (address: CreateAddressInput) => Promise<Address>
  removeAddress: (addressId: string) => Promise<void>
  updateAddress: (address: Address) => Promise<Address>
  setDefaultAddress: (addressId: string) => Promise<void>
}

export const useCustomer = create<CustomerStoreType>()(
  persist(
    (set, get) => ({
      customer: null,
      token: null,
      isLoaded: false,
      setIsLoaded: isLoaded => set({ isLoaded }),
      setCustomer: customer => set({ customer }),
      setToken: token => set({ token }),
      register: async input => {
        const customer = await shopify().customer.create(input)
        set({ customer })

        await get().login(input.email, input.password)
      },
      login: async (email, password) => {
        const token = await shopify().customer.login({ email, password })
        set({ token })

        const customer = await shopify().customer.fetch(token.accessToken)
        set({ customer })

        const cart = useCart.getState()
        if (cart.data != null) {
          await cart.updateBuyerIdentity(token.accessToken, customer)
        }
      },
      logout: async () => {
        const accessToken = get().token?.accessToken
        if (accessToken == null) return

        await shopify().customer.logout(accessToken)
        set({ customer: null, token: null })
      },
      refresh: async () => {
        const accessToken = get().token?.accessToken
        if (accessToken == null) return

        const newToken = await shopify().customer.refresh(accessToken)
        set({ token: newToken })
      },
      addAddress: async address => {
        const token = get().token?.accessToken
        if (token == null) throw new Error('No token')

        const newAddress = await shopify().customer.address.create(token, address)

        set(prev => ({
          customer: {
            ...prev.customer!,
            addresses: [...(prev.customer?.addresses ?? []), newAddress],
          },
        }))

        return newAddress
      },
      removeAddress: async addressId => {
        const token = get().token?.accessToken
        if (token == null) throw new Error('No token')

        await shopify().customer.address.remove(token, addressId)

        set(prev => ({
          customer: {
            ...prev.customer!,
            addresses: (prev.customer?.addresses ?? []).filter(address => address.id !== addressId),
          },
        }))
      },
      updateAddress: async address => {
        const token = get().token?.accessToken
        if (token == null) throw new Error('No token')

        const newAddress = await shopify().customer.address.update(token, address)

        set(prev => ({
          customer: {
            ...prev.customer!,
            addresses: (prev.customer?.addresses ?? []).map(a =>
              a.id === newAddress.id ? newAddress : a,
            ),
          },
        }))

        return newAddress
      },
      setDefaultAddress: async addressId => {
        const token = get().token?.accessToken
        if (token == null) throw new Error('No token')

        const newCustomer = await shopify().customer.address.setDefault(token, addressId)

        set({ customer: newCustomer })
      },
    }),
    {
      name: 'bs_customer',
      partialize: ({ customer, token }) => ({
        customer,
        token,
      }),
      onRehydrateStorage: () => async state => {
        if (!state) return

        try {
          if (state.token == null) throw new Error('No token')

          const newToken = await shopify().customer.refresh(state.token.accessToken)
          state.setToken(newToken)

          const customer = await shopify().customer.fetch(newToken.accessToken)
          state.setCustomer(customer)

          try {
            if (newToken) {
              const cart = useCart.getState()

              if (cart.data != null) {
                await cart.updateBuyerIdentity(newToken.accessToken, customer)
              }
            }
          } catch (e) {
            logger.error('CustomerStore > rehydrate > updateBuyer', e)
          }
        } catch (e) {
          state.setCustomer(null)
          state.setToken(null)
        }

        state.setIsLoaded(true)
      },
      skipHydration: true,
    },
  ),
)
