import { Cart, Customer } from 'shopify-types'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { getLocaleFromHostname } from '@/helpers/getLocaleFromHostname'
import { buildGTMItem, GTMItem, pushGTMEvent } from '@/helpers/googleTagManager'
import { logger } from '@/helpers/logger'
import { shopify as non_localized_shopify } from '@/shopify'

import { useCustomer } from './customerStore'

export const MAX_PER_ITEM_IN_CART = 8

const getGTMEventFromUpdate = (
  before: Cart['lines'],
  after: Cart['lines'],
  linesToUpdate: { id: string; quantity: number }[],
) => {
  return linesToUpdate.reduce((acc, curr) => {
    const lineBefore = before.find(line => line.id === curr.id)
    const lineAfter = after.find(line => line.id === curr.id)

    if (
      lineBefore &&
      lineAfter &&
      curr.quantity > lineBefore.quantity &&
      curr.quantity === lineAfter.quantity
    ) {
      acc.push(buildGTMItem(lineBefore.merchandise, curr.quantity - lineBefore.quantity))
    }
    return acc
  }, [] as GTMItem[])
}

const getGTMEventFromAdd = (
  after: Cart['lines'],
  linesToAdd: { merchandiseId: string; quantity: number }[],
) => {
  return linesToAdd.reduce((acc, curr) => {
    const fromCart = after.find(item => item.merchandise.id === curr.merchandiseId)
    if (fromCart) {
      acc.push(buildGTMItem(fromCart.merchandise, curr.quantity))
    }
    return acc
  }, [] as GTMItem[])
}

const shopify = () =>
  non_localized_shopify({ language: getLocaleFromHostname(window.location.hostname).toUpperCase() })

export type CartStoreType = {
  data: Cart | null
  // TODO: Remove when feature exists in Shopify
  // https://github.com/Shopify/storefront-api-feedback/discussions/262
  giftCards: string[]
  isOpen: boolean
  isLoaded: boolean
  isUpdating: boolean
  setCart: (checkout?: Cart | null) => void
  setIsOpen: (isOpen: boolean) => void
  setIsLoaded: (isLoaded: boolean) => void
  setIsUpdating: (isUpdating: boolean) => void
  getOrCreateCart: () => Promise<Cart>
  addToBasket: (linesToAdd: { variantId: string; quantity?: number }[]) => Promise<void>
  removeFromBasket: (variantIds: string[]) => Promise<void>
  updateQuantity: (
    linesToUpdate: { variantId: string; quantity: number; mode?: 'set' | 'add' }[],
  ) => Promise<void>
  applyDiscount: (discountCodes: string[]) => Promise<void>
  applyGiftCard: (giftCards: string[]) => Promise<void>
  updateBuyerIdentity: (token: string, customer: Customer) => Promise<void>
}

export const useCart = create<CartStoreType>()(
  persist(
    (set, get) => ({
      data: null,
      giftCards: [],
      isOpen: false,
      isLoaded: false,
      isUpdating: false,
      setCart: data => set({ data }),
      setIsOpen: isOpen => set({ isOpen }),
      setIsLoaded: isLoaded => set({ isLoaded }),
      setIsUpdating: isUpdating => set({ isUpdating }),
      getOrCreateCart: async () => {
        if (get().data == null) {
          set({ isUpdating: true })

          try {
            set({ data: await shopify().cart.create() })

            const { customer, token } = useCustomer.getState()
            if (customer != null && token != null) {
              await get().updateBuyerIdentity(token.accessToken, customer)
            }
          } finally {
            set({ isUpdating: false })
          }
        }

        const { data } = get()
        if (data == null) {
          throw new Error('Failed to create cart')
        }

        return data
      },
      addToBasket: async linesToAdd => {
        set({ isUpdating: true })

        try {
          // If no cart exists, create one
          const data = await get().getOrCreateCart()

          // Lines which are already in the cart and should be updated
          const existingLines = linesToAdd
            // Filter out lines which are not in the cart
            .filter(({ variantId }) => data.lines.some(line => line.merchandise.id === variantId))
            // Map linesToAdd to our shopify clients format
            .map(({ variantId, quantity = 1 }) => {
              const lineItem = data.lines.find(line => line.merchandise.id === variantId)!

              return {
                id: lineItem.id,
                quantity: Math.min(lineItem.quantity + quantity, MAX_PER_ITEM_IN_CART),
              }
            })
            // Filter out lines which do not change quantity
            .filter(({ id, quantity }) => {
              const lineItem = data.lines.find(line => line.id === id)!
              return quantity !== lineItem.quantity
            })

          // Lines which are not in the cart and should be added
          const newLines = linesToAdd
            // Filter out lines which are already in the cart
            .filter(({ variantId }) => !data.lines.some(line => line.merchandise.id === variantId))
            // Map linesToAdd to our shopify clients format
            .map(({ variantId, quantity = 1 }) => ({
              merchandiseId: variantId,
              quantity: Math.min(quantity, MAX_PER_ITEM_IN_CART),
            }))

          // Update existing lines
          if (existingLines.length > 0) {
            const updated = await shopify().cart.lineUpdate(data.id, existingLines)
            set({ data: updated })

            // Get items which were successfully updated in the cart and create GTM events for them
            const gtmItems = getGTMEventFromUpdate(data.lines, updated.lines, existingLines)

            // Push GTM events
            if (gtmItems.length > 0) {
              pushGTMEvent(gtmItems)
            }
          }

          // Add new lines
          if (newLines.length > 0) {
            const updated = await shopify().cart.lineCreate(data.id, newLines)
            set({ data: updated })

            // Get items which were successfully added to the cart and create GTM events for them
            const gtmItems = getGTMEventFromAdd(updated.lines, newLines)

            // Push GTM events
            if (gtmItems.length > 0) {
              pushGTMEvent(gtmItems)
            }
          }
        } finally {
          set({ isUpdating: false })
        }
      },
      removeFromBasket: async variantIds => {
        set({ isUpdating: true })

        try {
          const data = await get().getOrCreateCart()

          const linesToRemove = data.lines
            .filter(line => variantIds.includes(line.merchandise.id))
            .map(line => line.id)

          set({
            data: await shopify().cart.lineRemove(data.id, linesToRemove),
          })
        } finally {
          set({ isUpdating: false })
        }
      },
      updateQuantity: async linesToUpdate => {
        set({ isUpdating: true })

        try {
          const data = await get().getOrCreateCart()

          const mappedLinesToUpdate = linesToUpdate
            // Filter out lines which are not in the cart
            .filter(({ variantId }) => data.lines.some(line => line.merchandise.id === variantId))
            // Map linesToUpdate to our shopify clients format
            .map(({ variantId, quantity, mode }) => {
              const lineItem = data.lines.find(line => line.merchandise.id === variantId)!

              return {
                id: lineItem.id,
                quantity: Math.min(
                  mode === 'set' ? quantity : lineItem.quantity + quantity,
                  MAX_PER_ITEM_IN_CART,
                ),
              }
            })
            // Filter out lines which do not change quantity
            .filter(({ id, quantity }) => {
              const lineItem = data.lines.find(line => line.id === id)!
              return quantity !== lineItem.quantity
            })

          // If no lines need to be updated, return
          if (mappedLinesToUpdate.length === 0) return

          // Update the cart
          const updated = await shopify().cart.lineUpdate(data.id, mappedLinesToUpdate)
          set({ data: updated })

          // Get items which were successfully updated in the cart and create GTM events for them
          const gtmItems = getGTMEventFromUpdate(data.lines, updated.lines, mappedLinesToUpdate)

          // Push GTM events
          if (gtmItems.length > 0) {
            pushGTMEvent(gtmItems)
          }
        } finally {
          set({ isUpdating: false })
        }
      },
      applyDiscount: async discountCodes => {
        set({ isUpdating: true })

        try {
          const data = await get().getOrCreateCart()

          const existingCodes = data.discountCodes.map(({ code }) => code)
          const combined = [...existingCodes, ...discountCodes]

          set({
            data: await shopify().cart.discountApply(data.id, combined),
          })
        } finally {
          set({ isUpdating: false })
        }
      },
      applyGiftCard: async giftCards => {
        set({ isUpdating: true })

        try {
          const data = await get().getOrCreateCart()

          const existingCards = get().giftCards
          const combined = [...existingCards, ...giftCards]

          const newCart = await shopify().cart.giftCardApply(data.id, combined)
          set({
            data: newCart,
          })

          set({
            giftCards: combined.filter(code =>
              newCart.appliedGiftCards.some(applied => code.endsWith(applied.lastCharacters)),
            ),
          })
        } finally {
          set({ isUpdating: false })
        }
      },
      updateBuyerIdentity: async (token, customer) => {
        set({ isUpdating: true })

        try {
          const cart = get()
          if (cart.data == null) return

          await shopify().cart.buyerIdentityUpdate(cart.data.id, {
            customerAccessToken: token,
            email: customer.email,
            phone: customer.phone,
          })
        } finally {
          set({ isUpdating: false })
        }
      },
    }),
    {
      name: 'bs_cart',
      partialize: ({ data, giftCards }) => ({
        data,
        giftCards,
      }),
      onRehydrateStorage: () => async state => {
        if (state?.data == null) {
          state?.setIsLoaded(true)

          return
        }

        try {
          state.setCart(await shopify().cart.fetch(state.data.id))
        } catch (e) {
          logger.error('useCart > onRehydrateStorage', e)
          state.setCart(null)
        }

        state.setIsLoaded(true)
      },
      skipHydration: true,
    },
  ),
)
