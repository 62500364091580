import { sendGTMEvent } from '@next/third-parties/google'
import { Cart } from 'shopify-types'

import { logger } from './logger'

export type GTMItem = {
  title: string
  variant: {
    id: string
    title: string
    rawPrice: {
      amount: number
      currencyCode: string
    }
  }
  quantity: number
}

export const pushGTMEvent = (
  items: GTMItem[],
  event: 'add_to_cart' | 'view_item' = 'add_to_cart',
) => {
  if (items.length === 0) return

  const totalValue = items.reduce((acc, item) => {
    return acc + item.variant.rawPrice.amount * item.quantity
  }, 0)

  const finalEvent = {
    event,
    ecommerce: {
      currency: items[0].variant.rawPrice.currencyCode,
      value: totalValue,
      items: items.map(({ title, variant, quantity }) => ({
        item_id: variant.id.split('/').pop() ?? '',
        item_name: title,
        item_variant: variant.title,
        price: variant.rawPrice.amount,
        quantity: quantity,
      })),
    },
  }

  logger.debug('GTM Event', finalEvent)

  sendGTMEvent(finalEvent)
}

export const buildGTMItem = (
  variant: Cart['lines'][number]['merchandise'],
  quantity: number = 1,
): GTMItem => ({
  title: variant.product.title,
  variant: {
    id: variant.id,
    title: variant.title,
    rawPrice: {
      amount: variant.price.amount,
      currencyCode: variant.price.currencyCode,
    },
  },
  quantity: quantity,
})
